import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Row, Col, Button, Alert} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom';
import PaymentStatus from '../Order/PaymentStatus';

class OrderHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            loading: true
        };
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.active_account !== prevProps.active_account) {
            this.getOrders();
        }
    }

    async componentDidMount(){
        document.title = "Order History | Bevvy";
        this.getOrders();
    }

    getOrders(){
        this.setState({
            loading:true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT+'/orders/'+this.props.active_account)
            .then(function (response) {
                if (localStorage.getItem('show_success_multiple') == "true") {
                    localStorage.setItem('show_success_multiple', false);
                    Swal("Orders Successfully Placed", "<p>" + "Seperate receipts have been emailed to you, and your orders are now being processed. Thank you!</p>", "success");
                }
                if (localStorage.getItem('show_partial') == "true") {
                    localStorage.setItem('show_partial', false);
                    Swal("Order Partially Placed", "<p>Some of your selected items could not be ordered. An invoice has been emailed to you for the successfully ordered product(s).</p>", "warning");
                }
                self.setState({
                    orders: response.data,
                    loading: false
                });
            })
            .catch(function (error) {
                if (error.response.status===401){
                    self.props.userSignOut()
                } else {
                    self.setState({
                        orders:[],
                        loading:false
                    });
                }
            });
    }

    goToOrder = (order_id) => {
        this.props.history.push("/order/"+order_id);
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Order History</div>
                </div>
                <Alert color="danger" className={(this.state.orders.length==0 && !this.state.loading) ? 'text-center':'d-none'}>
                    You haven't placed an order yet. &nbsp;<Link to="place-order" className="btn btn-secondary btn-sm">Place One Now</Link>
                </Alert>
                <div className={(this.state.loading?"card card-default whirl traditional":"card card-default")}>
                    <div className="card-header">
                        <div className="card-title">{this.state.orders.length} Previous Orders</div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-hover table-pointer">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Kegs</th>
                                        <th>Cases</th>
                                        <th>Total</th>
                                        <th className="d-none d-sm-table-cell">Payment Method</th>
                                        <th className="d-none d-sm-table-cell">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.orders.map(function(item, key) {
                                        if (item.void_date == null) {
                                            return (
                                                <tr onClick={this.goToOrder.bind(this, item.order_id)}>
                                                    <td>{item.date_full}</td>
                                                    <td>{item.kegs}</td>
                                                    <td>{item.cases}</td>
                                                    <td>${item.total.toFixed(2)}</td>
                                                    <td className="d-none d-sm-table-cell">{(item.payment_method == 1 ? "Credit Card" : (item.payment_method == 3 ? "EFT" : (item.payment_method == 6 ? "E-Transfer" : "Payment on Delivery")))}</td>
                                                    <td className="d-none d-sm-table-cell">
                                                        <span
                                                            className={(item.company_id == 1 || item.company_id == 7 || item.company_id == 15 || item.company_id == 16 ? "d-none" : "")}>See Vendor</span>
                                                        <span
                                                            className={(item.company_id == 1 || item.company_id == 7 || item.company_id == 15 || item.company_id == 16 ? "" : "d-none")}>
                                                        <PaymentStatus is_paid={item.is_paid} company_id={item.company_id}/>
                                                    </span>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    },this)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (OrderHistory);
